
import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import Multiselect from 'vue-multiselect'
import Vuelidate from 'vuelidate'


//register globally

Vue.use(Vuelidate)
Vue.component('multiselect', Multiselect)
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.mixin({
  methods: {
    defaultHeaders() {
      return {
        "Content-type": "application/json",
       authorization: this.getBearerToken(),
      };
    },
    getBearerToken() {
     return "Bearer " + store.state.token;
    },
    refreshHeaders() {
      console.log("defaultHeaders: ", this.getBearerToken());
      return {
        "Content-type": "application/json",
        isRefreshToken: "true",
        authorization: this.getBearerToken(),
      };
    },
    refreshAccessToken() {
      console.log("refreshAccessToken called")
      let url = process.env.VUE_APP_API_HOST + "/emapp/web/secure/refreshtoken";
      fetch(url, {
        method: "GET",
        headers: this.refreshHeaders(),
      }).then((response) => {
        console.log("refreshAccessToken response", response);
        if (response && response.ok) {
          return response.json();
        } else {
          this.takeUserToLogin();
        }
      }).then((tokenResponse) => {
        console.log("refreshAccessToken tokenResponse", tokenResponse.token);
        console.log("token before save",store.state.token);

        if (tokenResponse && tokenResponse.token) {
          this.$store.commit("addToken", tokenResponse.token);
          
        console.log("token after save",store.state.token);
        }else{
          this.takeUserToLogin();
        }
      })
    },
    takeUserToLogin() {
      this.$store.commit("addUser", undefined);
    //  this.$router.push({ path: "/auth/login" });
    },
    // apiGetSecure(url) {
    //   return fetch(url, {
    //     method: "GET",
    //     headers: this.defaultHeaders(),
    //   }).then((response) => {
    //   //  console.log("response", response);
    //     if (response.ok) {
    //       return response;
    //     } else {
    //       console.log("Exception in apiGetSecure", response.status);
    //       if (response.status == 401) {
    //         console.log("status is 401");
    //         this.refreshAccessToken();
    //         //TODO call an api to refresh the access token
    //       } else {
    //         console.log("status is not 401");
    //       }
    //     }
    //   })
    // },

   
    apiGetSecure(url) {
      return fetch(url, {
        method: "GET",
        headers: this.defaultHeaders(),
      }).catch((error) => {
        console.log("api error", error);
        throw error;
      })
    },

    apiGet(url) {
      return fetch(url, {
        method: "GET",
        headers: this.defaultHeaders(),
      }).catch((error) => {
        console.log("api error", error);
        throw error;
      })
    },
    apiGetSecureWithParams(url, params) {
      console.log("apiGetSecureWithParams params: ", JSON.stringify(params));
      console.log("token", this.getBearerToken());
      return fetch(url, {
        method: "GET",
        headers: this.defaultHeaders(),
        params: params
      }).catch((error) => {
        console.log("api error", error);
        throw error;
      })
    },
    // apiPostSecure(url, body) {
    //   return fetch(url, {
    //     method: "POST",
    //     headers: this.defaultHeaders(),
    //     body: JSON.stringify(body),
    //   }).catch((error) => {
    //     console.log("api error in apiPostSecure", error);
    //     throw error;
    //   })
    // },

    // apiPost(url, body) {
    //   return fetch(url, {
    //     method: "POST",
    //     headers: this.defaultHeaders(),
    //     body: JSON.stringify(body),
    //   }).catch((error) => {
    //      console.log("api error in apiPostSecure", error);
    //     throw error;
    //   })
    // },

    apiPostSecure(url, body) {
      return fetch(url, {
        method: "POST",
        headers: this.defaultHeaders(),
        body: JSON.stringify(body),
      }).catch((error) => {
         console.log("api error in apiPostSecure", error);
        throw error;
      })
    },
    apiPostSecureWithParams(url, params, body) {
      console.log("apiPostSecureWithParams params: ", JSON.stringify(params));
      console.log("token", this.getBearerToken());
      return fetch(url, {
        method: "POST",
        headers: this.defaultHeaders(),
        params: params,
        body: JSON.stringify(body),
      }).catch((error) => {
        console.log("api error", error);
        throw error;
      })
    },
    
  }
})

const init = () => {
  new Vue({
    el: '#app',
    router,
    store,
    
    // icons,
    // template: '<App/>',
    // components: {
    //   App
    // },
  // state,
    beforeCreate() { this.$store.commit("initialize") },
    mounted() {
    //  console.log("in mounted()");
     // this.$store.commit("initialize");
      //this.$store.commit("addUser");
      if (store.state.token == null || store.state.token == undefined ) {
    //    console.log("token empty");
       // this.$route.push({ path: `/dashboard` });
       this.$router.push({ name: 'Login' });
      } else {
       console.log("token present");
         // router.push({ path: `/auth/Login` });
       //   this.takeUserToLogin();
          
      }
      
    },
    icons,
    template: '<App/>',
    components: {
      App
    }
  })
}

init();
// let globalUser;

// function checkUserLoggedIn() {
//   console.log("checkUserLoggedIn()");
//   if(globalUser){
//     globalUser.getIdTokenResult()
//     .then((idTokenResult) => {
//       let newToken = idTokenResult.accessToken;
//       let oldToken = store.state.accessToken;
//       if (newToken != oldToken) {
//         store.commit("addUser", idTokenResult);
//       }
//       console.log("timer", idTokenResult);
//     })
//     .catch((error) => {
//       console.log("timer", error);
//     });
//   }
// }
// init();
// setInterval(checkUserLoggedIn, 60000);

// // functionAuthstateChanged((user) => {
// //   if (user) {
   
// //     user.getIdTokenResult()
// //       .then((idTokenResult) => {
// //        store.commit("addUser", idTokenResult);
// //         init() //Note: for prefilled roles        
// //       })
// //       .catch((error) => {
// //         init()
// //         console.log(error);
// //       });
// //     console.log("$store", store);
// //   } else {
// //     init()
// //     // User is signed out.
// //     // ...
// //     // console.log("user not logged in");
// //     // router.push({ path: `/auth/login` });
// //   }
// // });

// new Vue({
//   el: '#app',
//   router,
//   store,
//   icons,
//   template: '<App/>',
//   components: {
//     App
//   }
// })



// import 'core-js/stable'
// import Vue from 'vue'
// import App from './App'
// import router from './router'
// import CoreuiVue from '@coreui/vue'
// import { iconsSet as icons } from './assets/icons/icons.js'
// import store from './store'
// import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
// import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
// import Multiselect from 'vue-multiselect'
// import Vuelidate from 'vuelidate'


// // register globally

// Vue.use(Vuelidate)
// Vue.component('multiselect', Multiselect)
// Vue.use(VueFilterDateParse);
// Vue.use(VueFilterDateFormat);
// Vue.config.performance = true
// Vue.use(CoreuiVue)
// Vue.prototype.$log = console.log.bind(console)
// Vue.mixin({
//   methods: {
//     defaultHeaders() {
//       return {
//         "Content-type": "application/json",
//         //authorization: this.getBearerToken(),
//       };
//     },
//     getBearerToken() {
//      return "Bearer " + store.state.token;
//     },
//     apiGetSecure(url) {
//       return fetch(url, {
//         method: "GET",
//         headers: this.defaultHeaders(),
//       }).catch((error) => {
//         console.log("api error", error);
//         throw error;
//       })
//     },
//     apiGetSecureWithParams(url, params) {
//       console.log("apiGetSecureWithParams params: ", JSON.stringify(params));
//       console.log("token", this.getBearerToken());
//       return fetch(url, {
//         method: "GET",
//         headers: this.defaultHeaders(),
//         params: params
//       }).catch((error) => {
//         console.log("api error", error);
//         throw error;
//       })
//     },
//     apiPostSecure(url, body) {
//       return fetch(url, {
//         method: "POST",
//         headers: this.defaultHeaders(),
//         body: JSON.stringify(body),
//       }).catch((error) => {
//         console.log("api error in apiPostSecure", error);
//         throw error;
//       })
//     },
//     apiPostSecureWithParams(url, params, body) {
//       console.log("apiPostSecureWithParams params: ", JSON.stringify(params));
//       console.log("token", this.getBearerToken());
//       return fetch(url, {
//         method: "POST",
//         headers: this.defaultHeaders(),
//         params: params,
//         body: JSON.stringify(body),
//       }).catch((error) => {
//         console.log("api error", error);
//         throw error;
//       })
//     },
//   }
// })

