
export const topic = ['369 466',`

g  id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<g>
    
<g>
<path d="M360,408h-80v-16h64c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V128c0.001-4.417-3.579-7.999-7.996-8    c-0.001,0-0.003,0-0.004,0H136c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v256c-0.001,4.417,3.579,7.999,7.996,8    c0.001,0,0.003,0,0.004,0h64v16h-80c-35.329,0.04-63.96,28.671-64,64c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0    h352c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004C423.96,436.671,395.329,408.04,360,408z M144,376V136h192v240H144z     M72.664,464C76.6,440.92,96.587,424.03,120,424h88c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-24h48v24    c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h88c23.413,0.03,43.4,16.92,47.336,40H72.664z"/>
</g>  
</g>
<g>
	<g>

	</g>
</g>
<g>
	<g>
		
	</g>
</g>
<g>
	<g>
		<rect x="376" y="376" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="440" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="104" y="400" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="160" y="336" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="272" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="152" y="240" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="64" y="256" width="16" height="16"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</g>
    

`]


