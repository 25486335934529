import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/auth/Login')
const Register = () => import('@/views/pages/Register')

// Views
const Dashboard = () => import('@/views/Dashboard')




//my users
const MyUsers = () => import('@/views/users/MyUsers')
const MyUser = () => import('@/views/users/User')

//Symptoms
const Symptoms = () => import('@/views/symptoms/symptoms')
const Symptom = () => import('@/views/symptoms/symptom')

const Briefings = () => import('@/views/Briefings/briefings')
const Briefing = () => import('@/views/Briefings/briefing')

const BriefNames = () => import('@/views/Briefings/briefNames')
const BriefName = () => import('@/views/Briefings/briefName')

const Topics = () => import('@/views/Briefings/briefTopics')
const Topic = () => import('@/views/Briefings/briefTopic')

//Topics

// Users
const Users = () => import('@/views/CMSusers/users')
const User = () => import('@/views/CMSusers/user')

const CMSUsers = () => import('@/views/CMSusers/cmsUsers')
const CMSUser = () => import('@/views/CMSusers/cmsUser')

const Workpermits = () => import('@/views/Workpermits/workpermits')
const Workpermit = () => import('@/views/Workpermits/workpermit')

const Sections = () => import('@/views/Sections/sections')
const Section = () => import('@/views/Sections/section')

const SubSections = () => import('@/views/Sections/subSections')
const SubSection = () => import('@/views/Sections/subSection')


const InputFields = () => import('@/views/Sections/inputFields')
const SubSectionInputFields = () => import('@/views/Sections/subSectionInputFields')
const SubSectionInputField = () => import('@/views/Sections/subSectionInputField')
const InputField = () => import('@/views/Sections/inputField')

const InputChecks = () => import('@/views/Sections/inputChecks')
const InputCheck=() => import ('@/views/Sections/inputCheck')
const InputTag=() => import ('@/views/Sections/tag')


const Jobs = () => import('@/views/Jobs/jobs')
const Job = () => import('@/views/Jobs/job')

const NewJob=()=>import('@/views/Jobs/newJob')


const Groups = () => import('@/views/Groups/groups')
const Group = () => import('@/views/Groups/group')

const SubGroups = () => import('@/views/Groups/subgroups')
const SubGroup = () => import('@/views/Groups/subgroup')

const mergeQC=()=>import('@/views/Groups/mergeQC')
const multiSelect=()=>import('@/containers/MultiSelect.vue')

const Departments = () => import('@/views/Department/departments')
const Department = () => import('@/views/Department/department')


const JobTypes = () => import('@/views/JobType/jobTypes')
const JobType = () => import('@/views/JobType/jobType')

const DepartmentUsers = () => import('@/views/DepartmentUsers/depusers')
const DepartmentUser = () => import('@/views/DepartmentUsers/depuser')

const Skills = () => import('@/views/CMSusers/skills')
const EdrsAvability = () => import('@/views/CMSusers/employeeAvability')

const Tasks = () => import('@/views/Checklists/tasks')
const Task = () => import('@/views/Checklists/task')

const Items = () => import('@/views/Checklists/Items')
const Item = () => import('@/views/Checklists/Item')

const Checks = () => import('@/views/Checklists/checks')
const Check = () => import('@/views/Checklists/check')
const Models = () => import('@/views/Equipments/models')
const Model = () => import('@/views/Equipments/model')

const Acknowledgements = () => import('@/views/Briefings/acknowledgements')
const Acknowledgement = () => import('@/views/Briefings/acknowledgement')

const Equipments = () => import('@/views/Equipments/equipments')
const Equipment = () => import('@/views/Equipments/equipment')

const Checklists = () => import('@/views/Checklists/checklists')
const Checklist = () => import('@/views/Checklists/checklist')

const Options = () => import('@/views/Options/options')
const Option = () => import('@/views/Options/option')

const SWPs = () => import('@/views/SWP/swps')
const SWP = () => import('@/views/SWP/swp')

const ItemTrackers = () => import('@/views/ItemTracker/itemtrackers')

const SWPItems = () => import('@/views/SWP/items')
const SWPItem = () => import('@/views/SWP/item')

const DepartmentCategories = () => import('@/views/Categories/categories')
const DepartmentCategory = () => import('@/views/Categories/category')

const Locations = () => import('@/views/Locations/locations')
const Location = () => import('@/views/Locations/location')

const SubLocations = () => import('@/views/Locations/sublocations')
const SubLocation = () => import('@/views/Locations/sublocation')

const Cranes = () => import('@/views/Crane/cranes')
const Crane = () => import('@/views/Crane/crane')

const CivilMaintenances = () => import('@/views/CivilMaintenance/civilMaintenances')
const JobTasks = () => import('@/views/CivilMaintenance/jobTasks')
const JobTask = () => import('@/views/CivilMaintenance/jobTask')

const Inspections = () => import('@/views/CivilMaintenance/inspections')
const Inspection = () => import('@/views/CivilMaintenance/inspection')
const TaskImage = () => import('@/views/CivilMaintenance/taskimages')
const Labours = () => import('@/views/CivilMaintenance/labours')
const Plants = () => import('@/views/CivilMaintenance/plants')
const AdvancedTables = () => import('@/views/Jobs/jobnew')
const TaskJobs = () => import('@/views/Jobs/jobTask')
const TaskSubmittdUsers = () => import('@/views/Jobs/taskSubmittedUsers')
const TaskItems = () => import('@/views/Jobs/taskItems')
const ItemChecklists = () => import('@/views/Jobs/ItemChecklists')
const ItemChecklist = () => import('@/views/Jobs/itemChecklist')
const JobOT = () => import('@/views/Jobs/jobOT')
const JobAssignedUsers = () => import('@/views/Jobs/jobAssignedUsers')
const WorkPermitRequests = () => import('@/views/Workpermits/WorkpermitRequests')
const PermitOfficers = () => import('@/views/Workpermits/permitOfficers')
const SectionFormData = () => import('@/views/Workpermits/sectionFormData')
const SubSectionFormData = () => import('@/views/Workpermits/subSectionFormData')
const InputFieldValue = () => import('@/views/Workpermits/inputFieldValue')
const QmsJobs = () => import('@/views/QMSJobs/qmsJobs')
const Defects = () => import('@/views/QMSJobs/defects')
const Defect = () => import('@/views/QMSJobs/defect')
//QMS Templates
const QMSTemplates = () => import('@/views/QMSJobs/qmsTemplates')
const QMSTemplate = () => import('@/views/QMSJobs/qmsTemplate')
const QMSModels = () => import('@/views/QMSJobs/qmsModels')
const QMSModel = () => import('@/views/QMSJobs/qmsModel')
const QMSInspections = () => import('@/views/QMSJobs/qmsInspections')
const QMSInspection = () => import('@/views/QMSJobs/qmsInspection')
//MED Meta Data
const ApprovalSkillSets = () => import('@/views/Settings/approvalPermitSkillSets')
const ApprovalSkillSet = () => import('@/views/Settings/approvalPermitSkillSet')
const MEDMetadata = () => import('@/views/MEDMetadata/medMetadata')
const MEDLocation = () => import('@/views/MEDMetadata/medLocation')
const TyreSize = () => import('@/views/MEDMetadata/tyreSize')
const TyrePosition = () => import('@/views/MEDMetadata/tyrePosition')
const TyreRemovalReason = () => import('@/views/MEDMetadata/tyreRemovalReason')
const TyreNewType = () => import('@/views/MEDMetadata/tyreNewType')
const TyreChangeForms = () => import('@/views/MEDFormData/tyreChangeForms')
const TyreChangeForm = () => import('@/views/MEDFormData/tyreChangeForm')
const TyreDepartments = () => import('@/views/TyreDepartment/tyreDepartments')
const TyreDepartment = () => import('@/views/TyreDepartment/tyreDepartment')
const SettingValues =() => import('@/views/SettingValues/settingValues')
const SettingValue =() => import('@/views/SettingValues/settingValue')




Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        

        // {
        //  path: 'subgroup/:groupId',
        //   name:'mergeQC',
        //   component:mergeQC
        // },
        {
          path: '/defects/:qmsJobId',
          name: 'Defects',
          component: Defects
        },
        {
          path: '/defect/:defectId',
          name: 'Defect',
          component: Defect
        },
        {
          path: '/qmsJobs',
          name: 'QmsJobs',
          component: QmsJobs
        },
        {
          path: 'workpermitRequests',
          meta: {
                    label: 'Work Permit Requests'
                  },
          name: 'WorkPermitRequests',
          component: WorkPermitRequests
        },
        {
          path: 'permitOfficers/:requestId',
          meta: {
                    label: 'Assign Officers'
                  },
          name: 'PermitOfficers',
          component: PermitOfficers
        },
        {
          path: '/sectionFormData/:requestId',
          meta: {
            label: 'Section Form Data'
          },
          name: 'SectionFormData',
          component: SectionFormData
        },
        {
          path: '/inputFieldValue/:sectionFormId',
          meta: {
            label: 'Input Field Value'
          },
          name: 'InputFieldValue',
          component: InputFieldValue
        },
        {
          path: '/subSectionFormData/:requestId',
          meta: {
            label: 'Sub Section Form Data'
          },
          name: 'SubSectionFormData',
          component: SubSectionFormData
        },
       

        {
          path: 'cranes',
          name: 'Cranes',
          component: Cranes
        },
        {
          path: 'crane',
          name: 'Crane',
          component: Crane
        },
        {
          path: 'user/:uid',
          meta: {
                    label: 'User Details'
                  },
          name: 'User',
          component: User
        },
        
        {
          path: 'users',
          name: 'Users',
          component: Users
        },
        {
          path: 'cmsUser/:uid',
          meta: {
            label: 'User Details'
          },
          name: 'CMSUser',
          component: CMSUser
        },
        
        {
          path: 'cmsUsers',
          meta: {
            label: 'CMS Users'
          },
          name: 'CMSUsers',
          component: CMSUsers
        },
        
        {
          path: 'symptom',
          name: 'Symptom',
          component: Symptom
        },
       
        
        {
          path: 'symptoms',
          name: 'Symptoms',
          component: Symptoms
        },
       
        {
          path: 'briefings',
          name: 'Briefings',
          component:  Briefings
        },

        {
          path: 'briefing/:briefingId',
          name: 'Briefing',
          component:  Briefing 
        },

        {
          path: '/briefings/briefing/add/:mode',
          meta: {
            label: 'New Briefing'
          },
      
          name: 'NewBriefing',
          component: Briefing
        },
        {
          path: 'briefNames/:subGroupId/:groupId',
          meta: {
            label: 'Brief Names'
          },
          name: 'BriefNames',
          component:  BriefNames
        },
        {
          path: 'briefName/:subGroupId/:mode',
          meta: {
            label: 'New Brief Name'
          },
          name: 'NewBriefName',
          component: BriefName
        },
        {
          path: 'briefName/:briefId/:subGroupId',
          meta: {
            label: 'Brief Name'
          },
          name: 'BriefName',
          component:  BriefName
        },
        {
          path: 'acknowledgements/:briefingId',
          name: 'Acknowledgements',
          component: Acknowledgements
        },
        {
          path: 'acknowledgement/:briefingId/:acknowledgeId',
          name: 'Acknowledgement',
          component: Acknowledgement
        },

        {
          path: 'equipments/:subGroupId',
          name: 'Equipments',
          component: Equipments
        },

        {
          path: 'equipment/:equipmentId',
          name: 'Equipment',
          component: Equipment
        },
      
        {
          path: 'checklist/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Checklist',
          component: Checklist
        },
        
        {
          path: 'checklists/:modelId/:subGroupId/:groupId',
          name: 'Checklists',
          component: Checklists
        },
        {
          path: 'topics/:briefId',
          meta: {
            label: 'Brief Topics'
          },
          name: 'Topics',
          component: Topics
        },
        {
          path: 'topic/:topicId',
          meta: {
            label: 'Brief Topic'
          },
          name: 'Topic',
          component:Topic
        },
        {
          path: 'topic/:briefId/:mode',
          meta: {
            label: 'New Brief Topic'
          },
          name: 'NewTopic',
          component:Topic
        },
        
        {
          path: 'workpermits/:groupId',
          name: 'Work Permits',
          component: Workpermits
        },
        {
          path: 'workpermit/:permitFormId/:groupId/:mode',
          meta: {
            label: 'Work Permit'
          },
          name: 'WorkPermit',
          component: Workpermit
        },
        {
          path: 'workpermit/:groupId/:mode',
          meta: {
            label: 'New Work Permit'
          },
          name: 'NewWorkPermit',
          component: Workpermit
        },
        {
          path: 'sections/:permitFormId/:groupId',
          name: 'Sections',
          component: Sections
        },

        {
          path: 'section/:sectionId/:permitFormId/:groupId',
          name: 'Section',
          component: Section
        },
        {
          path: 'subsection/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Sub Sections'
          },
          name: 'SubSections',
          component: SubSections
        },
        {
          path: 'inputFields/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Input Fields'
          },
          name: 'InputFields',
          component: InputFields
        },
        {
          path: 'subSectionInputFields/:subSectionId/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Sub Section Input Fields'
          },
          name: 'SubSectionInputFields',
          component: SubSectionInputFields
        },
        {
          path: 'subSectionInputField/:subSectionId/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Sub Section Input Field'
          },
          name: 'SubSectionInputField',
          component: SubSectionInputField
        },
        {
          path: 'inputField/:inputFieldId/:sectionId/:permitFormId/:groupId/:mode',
          meta: {
            label: 'Input Field'
          },
          name: 'InputField',
          component: InputField
        },
        {
          path: 'inputField/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'New Input Field'
          },
          name: 'NewInputField',
          component: InputField
        },
        {
          path: 'inputChecks/:inputFieldId/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Input Checks'
          },
          name: 'InputChecks',
          component: InputChecks
        },
        {
          path: 'inputChecks/:checkId/:sectionId/:permitFormId/:groupId/:mode',
          meta: {
            label: 'Input Check'
          },
          name: 'InputCheck',
          component: InputCheck
        },
        {
          path: 'inputChecks/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'New Check'
          },
          name: 'NewCheck',
          component: InputCheck
        },
        {
          path: 'inputChecks/:tagId/:inputFieldId/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Input Tag'
          },
          name: 'InputTag',
          component: InputTag
        },
        {
          path: 'subsection/:subSectionId/:sectionId/:permitFormId/:groupId',
          meta: {
            label: 'Sub Section'
          },
          name: 'SubSection',
          component: SubSection
        },
        {
          path: 'groups',
          name: 'Groups',
          component: Groups
        },

        {
          path: 'group/:groupId/:mode',
          meta: {
            label: 'View Group'
          },
          name: 'Group',
          component: Group
        },
        {
          path: 'group',
          meta: {
            label: 'New Group'
          },
          name: 'NewGroup',
          component: Group
        },
        {
          path: 'locations/:groupId',
          name: 'Locations',
          component: Locations
        },
        {
          path: 'locations/:locationId/:groupId',
          name: 'Location',
          component: Location
        },
        {
          path: 'sublocations/:locationId',
          name: 'Sub Locations',
          component: SubLocations
        },
        {
          path: 'sublocation/:subLocationId/:locationId',
          name: 'Sub Location',
          component: SubLocation
        },
        {
          path: 'subgroups/:groupId',
          name: 'Sub Groups',
          component: SubGroups
        },
        {
          path: 'subgroup/:groupId/:subgroupId',
          name: 'Sub Group',
          component: SubGroup
        },
        // {
        //   path: 'departments/:subGroupId',
        //   name: 'Departments',
        //   component: Departments
        // },
        {
          path: 'department/:departmentId/:subGroupId/:groupId/:mode',
          name: 'Department',
          component: Department
        },
        {
          path: 'department/:subGroupId/:groupId/:mode',
          meta: {
            label: 'New Department'
          },
          
          name: 'NewDepartment',
          component: Department
        },
        {
          path: 'departments/:subGroupId/:groupId',
          name: 'Departments',
          component: Departments
        },
        {
          path: 'jobs',
          name: 'Jobs',
          component: Jobs
        },
        {
          path: 'job',
          name: 'Job',
          component: Job
        },

        {
          path:'newJob/:mode',
          name:'NewJob',
          component:NewJob
        },
        
       
        {
          path: 'jobTasks/:jobId',
          name: 'JobTasks',
          component: TaskJobs
        },
        {
          path: 'jobAssignedUsers/:jobId',
          meta: {
            label: 'Job Assigned Users'
          },
          name: 'JobAssignedUsers',
          component: JobAssignedUsers
        },
        {
          path: 'taskSubmittdUsers/:jobTaskId',
          meta: {
            label: 'Task Submittd Users'
          },
          name: 'TaskSubmittdUsers',
          component: TaskSubmittdUsers
        },
        {
          path: 'jobOT/:jobId',
          meta: {
            label: 'Job OT'
          },
          name: 'JobOT',
          component: JobOT
        },
        {
          path: 'taskItems/:jobTaskId/:jobId',
          name: 'Task Items',
          component: TaskItems
        },
        {
          path: 'itemChecklists/:taskItemId/:jobTaskId/:jobId',
          name: 'Item Checklists',
          component: ItemChecklists
        },
        {
          path: 'itemChecklist/:itemChecklistId/:mode',
          name: 'ItemChecklist',
          component: ItemChecklist
        },
        {
          path: 'depusers/:departmentId',
          name: 'Department Users',
          component: DepartmentUsers
        },

        {
          path: 'depuser/:departmentId/:uid',
          name: 'Department User',
          component: DepartmentUser 
        },
        
        {
          path: 'swp/:categoryId/:subGroupId/:groupId',
          name: 'SWP',
          component: SWP
        }, {
          path: 'swps/:subGroupId/:groupId',
          name: 'SWPs',
          component: SWPs
        },
        {
          path: 'items/:categoryId/:subGroupId/:groupId',
          name: 'SWP Items',
          component: SWPItems
        },
        {
          path: 'item/:swpItemId/:categoryId/:subGroupId/:groupId',
          name: 'SWP Item',
          component: SWPItem
        },
        {
          path: 'categories/:departmentId/:subGroupId/:groupId',
          name: 'Department Categories',
          component: DepartmentCategories
        },
        {
          path: 'category/:categoryId/:departmentId/:subGroupId/:groupId',
          name: 'Department Category',
          component: DepartmentCategory
        },
        {
          path: 'itemtrackers/:categoryId/:departmentId/:subGroupId/:groupId',
          name: 'Item Trackers',
          component: ItemTrackers
        },
        {
          path: 'jobs',
          name: 'Job Types',
          component: JobTypes 
        },
        {
          path: 'job',
          name: 'Job Type',
          component: JobType 
        },
        {
          path: 'tasks/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Tasks',
          component: Tasks
        },

        {
          path: 'task/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Task',
          component: Task
        },
        {
          path: 'Items/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Items',
          component: Items
        },

        {
          path: 'checks/:itemId/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Checks',
          component: Checks
        },
        {
          path: 'check/:checkId/:itemId/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Check',
          component: Check
        },
        {
          path: 'Item/:itemId/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Item',
          component: Item
        }, 

        {
          path: 'models/:subGroupId/:groupId',
          meta: {
            label: 'Equipments'
          },
          name: 'Models',
          component: Models
        }, 
        {
          path: 'model/:modelId/:subGroupId/:groupId',
          meta: {
            label: 'Equipment'
          },
          name: 'Model',
          component: Model
        }, 
        {
          path: 'options/:checkId/:itemId/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Options',
          component: Options
        }, 
        {
          path: 'option/:checkOptionId/:checkId/:itemId/:taskId/:checklistId/:modelId/:subGroupId/:groupId',
          name: 'Option',
          component: Option
        }, 
        {
          path: 'civilMaintenances',
          name: 'Civil Maintenances',
          component: CivilMaintenances
        },
        {
          path: 'jobTasks/:jobId',
          name: 'Job Tasks',
          component: JobTasks
        },{
          path: 'jobTask/:jobTaskId/:jobId',
          name: 'Job Task',
          component: JobTask
        },
        {
          path: 'taskimages/:taskImageId/:jobTaskId/:jobId',
          name: 'Task Image',
          component: TaskImage
        },
        {
          path: 'inspections/:jobId',
          name: 'Inspections',
          component: Inspections
        },
        {
          path: 'inspection/:inspectionId/:jobId',
          name: 'Inspection',
          component: Inspection
        },
        {
          path: 'labours/:progressId/:jobId',
          name: 'Labours',
          component: Labours
        },
        {
          path: 'plants/:progressId/:jobId',
          name: 'Plants',
          component: Plants
        },
        {
          path: 'MyUsers',
          name: 'MyUsers',
          component: MyUsers
        },

        {
          path: 'qmsTemplates',
          meta: {
            label: 'QMS Templates'
          },
          name: 'QMSTemplates',
          component: QMSTemplates
        },
        {
          path: 'qmsTemplate',
          meta: {
            label: 'New Template'
          },
          name: 'NewQMSTemplate',
          component: QMSTemplate
        },
        {
          path: 'qmsTemplate/:qmsTemplateId/:mode',
          meta: {
            label: 'View Template'
          },
          name: 'QMSTemplate',
          component: QMSTemplate
        },
        {
          path: 'qmsModels/:qmsTemplateId',
          meta: {
            label: 'QMS Models'
          },
          name: 'QMSModels',
          component: QMSModels
        },
        {
          path: 'qmsModel/:qmsModelId/:mode',
          meta: {
            label: 'View Model'
          },
          name: 'QMSModel',
          component: QMSModel
        },
        {
          path: 'qmsModel/:qmsTemplateId',
          meta: {
            label: 'New Model'
          },
          name: 'NewQMSModel',
          component: QMSModel
        },
        {
          path: 'qmsInspection/:qmsInspectionId/:mode',
          meta: {
            label: 'View Inspection'
          },
          name: 'QMSInspection',
          component: QMSInspection
        },
        {
          path: 'qmsInspection',
          meta: {
            label: 'New Inspection'
          },
          name: 'NewQMSInspection',
          component: QMSInspection
        },
        {
          path: 'qmsInspections/:qmsModelId',
          meta: {
            label: 'QMS Inspections'
          },
          name: 'QMSInspections',
          component: QMSInspections
        },
        
        {
          path: 'jobnew',
          meta: {
            label: 'Advanced Tables'
          },
          name: 'AdvancedTables',
          component: AdvancedTables
        },
        {
          path: 'medMatadata',
          meta: {
            label: 'MED Meta Data'
          },
          name: 'MEDMetadata',
          component: MEDMetadata
        },
        {
          path: 'approvalPermitSkillSets',
          meta: {
            label: 'Approval Skill Sets'
          },
          name: 'ApprovalSkillSets',
          component: ApprovalSkillSets
        },
      
        {
          path: 'approvalPermitSkillSet/:approvalSkillSetId/:mode',
          meta: {
            label: 'Approval Skill Set'
          },
          name: 'ApprovalSkillSet',
          component: ApprovalSkillSet
        },
      
        {
          path: 'approvalPermitSkillSet/:mode',
          meta: {
            label: 'New Approval Skill Set'
          },
          name: 'NewApprovalSkillSet',
          component: ApprovalSkillSet
        },
       
        {
          path: 'skills/:ic_n',
          meta: {
            label: 'Employee Skills'
          },
          name: 'Skills',
          component: Skills
        },
        {
          path: 'employeeAvability/:ic_n',
          meta: {
            label: 'Employee Avability'
          },
          name: 'EdrsAvability',
          component: EdrsAvability
        },
        {
          path: 'medLocation/:medLocationId/:mode',
          meta: {
            label: 'MED Location'
          },
          name: 'MEDLocation',
          component: MEDLocation
        },
        {
          path: 'medLocation',
          meta: {
            label: 'New MED Location'
          },
          name: 'NewMEDLocation',
          component: MEDLocation
        },
        {
          path: 'tyreSize/:tyreSizeId/:mode',
          meta: {
            label: 'Tyre Size'
          },
          name: 'TyreSize',
          component: TyreSize
        },
        {
          path: 'tyreSize',
          meta: {
            label: 'New Tyre Size'
          },
          name: 'NewTyreSize',
          component: TyreSize
        },
        {
          path: 'tyrePosition/:tyrePositionId/:mode',
          meta: {
            label: 'Tyre Position'
          },
          name: 'TyrePosition',
          component: TyrePosition
        },
        {
          path: 'tyrePosition',
          meta: {
            label: 'New Tyre Position'
          },
          name: 'NewTyrePosition',
          component: TyrePosition
        },
        {
          path: 'tyreRemovalReason/:tyreRemovalReasonId/:mode',
          meta: {
            label: 'Tyre Removal Reason'
          },
          name: 'TyreRemovalReason',
          component: TyreRemovalReason
        },
        {
          path: 'tyreRemovalReason',
          meta: {
            label: 'New Tyre Removal Reason'
          },
          name: 'NewTyreRemovalReason',
          component: TyreRemovalReason
        },
        {
          path: 'tyreNewType/:tyreNewTypeId/:mode',
          meta: {
            label: 'Tyre New Type'
          },
          name: 'TyreNewType',
          component: TyreNewType
        },
        {
          path: 'tyreRemovalReason',
          meta: {
            label: 'New Tyre New Type'
          },
          name: 'NewTyreNewType',
          component: TyreNewType
        },
        {
          path: 'tyreChangeForms',
          meta: {
            label: 'Tyre Change Form Data'
          },
          name: 'TyreChangeForms',
          component: TyreChangeForms
        },
        {
          path: 'tyreChangeForm/:tyreFormId/:mode',
          meta: {
            label: ' View Tyre Change Form Data'
          },
          name: 'TyreChangeForm',
          component: TyreChangeForm
        },
        {
          path: 'tyreDepartments',
          meta: {
            label: 'Tyre Departments'
          },
          name: 'TyreDepartments',
          component: TyreDepartments
        },
        {
          path: 'tyreDepartment/:tyreDepartmentId',
          meta: {
            label: 'Tyre Department'
          },
          name: 'TyreDepartment',
          component: TyreDepartment
        },
        {
          path: 'tyreDepartment',
          meta: {
            label: 'New Tyre Department'
          },
          name: 'NewTyreDepartment',
          component: TyreDepartment
        },
        {
          path: 'settingValues',
          // meta: {
          //   label: 'Setting Int Values'
          // },
          name: 'SettingValues',
          component: SettingValues
        },
        // {
        //   path: '/settingValue',
        //   meta: {
        //     label: 'Setting Int Values'
        //   },
        //   name: 'SettingValue',
        //   component: SettingValue
        // },
        {
          path: '/settingValue',
          meta: {
            label: 'Setting Values'
          },
          name: 'SettingValue',
          component: SettingValue
        },
        
        // {
        //   path: 'base',
        //   redirect: '/base/cards',
        //   name: 'Base',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'cards',
        //       name: 'Cards',
        //       component: Cards
        //     },
        //     {
        //       path: 'forms',
        //       name: 'Forms',
        //       component: Forms
        //     },
        //     {
        //       path: 'switches',
        //       name: 'Switches',
        //       component: Switches
        //     },
        //     {
        //       path: 'tables',
        //       name: 'Tables',
        //       component: Tables
        //     },
        //     {
        //       path: 'tabs',
        //       name: 'Tabs',
        //       component: Tabs
        //     },
        //     {
        //       path: 'breadcrumbs',
        //       name: 'Breadcrumbs',
        //       component: Breadcrumbs
        //     },
        //     {
        //       path: 'carousels',
        //       name: 'Carousels',
        //       component: Carousels
        //     },
        //     {
        //       path: 'collapses',
        //       name: 'Collapses',
        //       component: Collapses
        //     },
        //     {
        //       path: 'jumbotrons',
        //       name: 'Jumbotrons',
        //       component: Jumbotrons
        //     },
        //     {
        //       path: 'list-groups',
        //       name: 'List Groups',
        //       component: ListGroups
        //     },
        //     {
        //       path: 'navs',
        //       name: 'Navs',
        //       component: Navs
        //     },
        //     {
        //       path: 'navbars',
        //       name: 'Navbars',
        //       component: Navbars
        //     },
        //     {
        //       path: 'paginations',
        //       name: 'Paginations',
        //       component: Paginations
        //     },
        //     {
        //       path: 'popovers',
        //       name: 'Popovers',
        //       component: Popovers
        //     },
        //     {
        //       path: 'progress-bars',
        //       name: 'Progress Bars',
        //       component: ProgressBars
        //     },
        //     {
        //       path: 'tooltips',
        //       name: 'Tooltips',
        //       component: Tooltips
        //     }
        //   ]
        // },
        // {
        //   path: 'buttons',
        //   redirect: '/buttons/standard-buttons',
        //   name: 'Buttons',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'standard-buttons',
        //       name: 'Standard Buttons',
        //       component: StandardButtons
        //     },
        //     {
        //       path: 'button-groups',
        //       name: 'Button Groups',
        //       component: ButtonGroups
        //     },
        //     {
        //       path: 'dropdowns',
        //       name: 'Dropdowns',
        //       component: Dropdowns
        //     },
        //     {
        //       path: 'brand-buttons',
        //       name: 'Brand Buttons',
        //       component: BrandButtons
        //     }
        //   ]
        // },
        // {
        //   path: 'icons',
        //   redirect: '/icons/coreui-icons',
        //   name: 'CoreUI Icons',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'coreui-icons',
        //       name: 'Icons library',
        //       component: CoreUIIcons
        //     },
        //     {
        //       path: 'brands',
        //       name: 'Brands',
        //       component: Brands
        //     },
        //     {
        //       path: 'flags',
        //       name: 'Flags',
        //       component: Flags
        //     }
        //   ]
        // },
    //     {
    //       path: 'notifications',
    //       redirect: '/notifications/alerts',
    //       name: 'Notifications',
    //       component: {
    //         render (c) { return c('router-view') }
    //       },
    //       children: [
    //         {
    //           path: 'alerts',
    //           name: 'Alerts',
    //           component: Alerts
    //         },
    //         {
    //           path: 'badges',
    //           name: 'Badges',
    //           component: Badges
    //         },
    //         {
    //           path: 'modals',
    //           name: 'Modals',
    //           component: Modals
    //         }
    //       ]
    //     }
       
        
      ]
    },
    {
      path: '/auth',
      redirect: '/pages/404',
     
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
       
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

