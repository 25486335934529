import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  groupNames:[],
  groupName:null,
  roles: [],
  authorities: [],
  role: null,
  authority: null,
  token: null,
  myroles:'',
  mygroups:''
}

const mutations = {
  // {"sidebarShow":"responsive","sidebarMinimize":false,"roles":["ROLE_EDITOR"],"role":{"ROLE_ADMIN":true,"ROLE_EDITOR":true},"authority":null}
 
  initialize(state) {
    if (localStorage.getItem("user-auth")) {
      let userAuth = localStorage.getItem("user-auth");
      console.log("userAuth: ", userAuth);
      if (userAuth) {
         //this.addUser(this.state, JSON.parse(userAuth));
        let user = JSON.parse(userAuth);
        // console.log("addUser() user: ", user);
        if (user) {
          localStorage.setItem("user-auth", JSON.stringify(user));
        } else {
          localStorage.setItem("user-auth", undefined);
        }
        state.role = null;
        state.roles = [];
        state.groupNames=[];
        //state.authorities = [];
        //state.authority = null;
        state.groupName=null;
        state.token = null;
        state.myroles='';
        state.mygroups='';
        if (user != undefined) {
          console.log("addUser state", state, JSON.stringify(state));
          console.log("addUser user", user, JSON.stringify(user));
          state["token"] = user.accessToken;
          //state["role"] = user.authority;
          if(user.groupName!= undefined){
          this.mygroups = user.groupName.split(" ");
          console.log("mygroups",this.mygroups);
          state["groupNames"] = this.mygroups;
        }
          this.myroles = user.authority;
          state["roles"] =  this.myroles;
        }
        console.log("last addUser state", state, JSON.stringify(state));
        console.log("$store.state.token ",state.token);

      }
    }
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  addToken(state,token){
    state.token = null;
    state["token"] = token;
    console.log("addToken state", state, JSON.stringify(state));
  },
 
  addUser(state, user) {
    console.log("addUser() user: ", user);
    if (user) {
      localStorage.setItem("user-auth", JSON.stringify(user));
    } else {
      localStorage.setItem("user-auth", undefined);
    }
    state.role = null;
    state.roles = [];
   // state.authorities = [];
    //state.authority = null;
    state.groupNames=[];
    state.groupName=null;
    state.token=null;
    //console.log("addUser state", state, JSON.stringify(state));
    //console.log("addUser user", user, JSON.stringify(user));
    //console.log("accessToken", user.accessToken, JSON.stringify(user.accessToken));
    if (user != undefined) {
      console.log("addUser state", state, JSON.stringify(state));
      console.log("addUser user", user, JSON.stringify(user));
      state["token"] = user.accessToken;
      this.myroles = user.authority;
      console.log("rolesarray",this.myroles);
      state["roles"] =  this.myroles;

      if(user.groupName!=undefined){
      this.mygroups = user.groupName.split(" ");
          console.log("mygroups",this.mygroups);
      state["groupNames"] = this.mygroups;
    
      
          if (this.myroles) {
            let rBind = {}
            for (let role in this.myroles) {
              rBind[this.myroles[role]] = true;
            }
            state.role = {...state.role, ...rBind}
          }
      
        }
      // if (user.authorities) {
      //   let aBind = {}
      //   for (let authority in user.claims.authorities) {
      //     aBind[user.claims.authorities[authority]] = true;
      //   }
      //   state.authority = {...state.authority, ...aBind}
      // }
    }
   
  
    console.log("last addUser state", state, JSON.stringify(state));
  }
}

export default new Vuex.Store({
  state,
  mutations
})
