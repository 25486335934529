
export const crane = ['369 466',`

g  id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<g>
    

<g>
	<g>
    <path xmlns="http://www.w3.org/2000/svg" d="m414.987 399.603c-.051-1.968-.48-3.909-1.303-5.742-1.536-3.419 5.358 4.621-117.63-128.262 28.781-23.758 30.682-66.714 4.651-93.495-8.338-8.578-18.565-14.472-29.697-17.261v-43.843h25c7.713 0 14.169-5.85 14.928-13.526l8-81c.871-8.833-6.078-16.474-14.929-16.474h-96c-8.868 0-15.798 7.66-14.927 16.474l8 81c.758 7.676 7.214 13.526 14.927 13.526h25v57c0 8.284 6.716 15 15 15 17.888 0 33 15.57 33 34 0 18.196-14.804 33-33 33-11.068 0-21.347-5.511-27.495-14.741-4.593-6.895-13.905-8.761-20.799-4.169-6.895 4.592-8.762 13.905-4.169 20.8 3.488 5.237 7.679 9.84 12.396 13.731-122.558 132.419-115.412 124.512-116.563 126.308-1.02 1.592-1.763 3.42-2.121 5.412-.359 2.007-.249-5.916-.249 99.659 0 8.284 6.716 15 15 15h288c8.284 0 15-6.716 15-15 0-107.3.01-96.248-.02-97.397zm-190.426-369.603h62.891l-5.037 51h-52.817zm42.952 248.928 98.172 106.072h-219.357l98.158-106.058c7.381 1.367 15.084 1.459 23.027-.014zm117.494 203.072h-258v-67h258z"/>
	</g>
</g>
<g>
	<g>
		
	</g>
</g>
<g>
	<g>
		<rect x="376" y="376" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="440" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="104" y="400" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="160" y="336" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="272" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="152" y="240" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="64" y="256" width="16" height="16"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</g>
    

`]


