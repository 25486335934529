export const groups = ['369 466',`
  
<g  id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<g>
    <path xmlns="http://www.w3.org/2000/svg" d="m512 256c0-47.91-35.287-87.733-81.236-94.846-5.024-9.217-10.811-18.104-17.237-26.433-2.53-3.28-7.242-3.885-10.52-1.355-3.279 2.53-3.886 7.24-1.355 10.52 4.002 5.187 7.737 10.608 11.17 16.196-3.787.125-7.519.466-11.184 1.019-13.163-20.122-30.613-37.572-50.737-50.736.553-3.664.894-7.396 1.019-11.183 9.145 5.617 17.816 12.036 25.874 19.173 1.427 1.265 3.202 1.886 4.97 1.886 2.072 0 4.135-.854 5.617-2.527 2.746-3.101 2.459-7.841-.642-10.587-11.287-9.999-23.697-18.687-36.89-25.879-7.11-45.955-46.935-81.247-94.849-81.247-47.905 0-87.724 35.279-94.844 81.221-33.897 18.457-61.476 46.035-79.936 79.935-45.941 7.12-81.22 46.938-81.22 94.843 0 33.355 17.106 62.785 43.003 79.992.378.306.786.575 1.22.804 11.006 7.074 23.543 11.962 36.995 14.047 18.464 33.909 46.041 61.484 79.939 79.939 4.212 27.174 19.867 50.612 41.866 65.231.379.307.788.576 1.222.805 14.943 9.605 32.71 15.182 51.755 15.182 19.046 0 36.814-5.577 51.758-15.183.43-.227.836-.495 1.211-.798 22.004-14.618 37.662-38.059 41.875-65.236 33.906-18.46
     61.471-46.023 79.933-79.929 20.032-3.073 38.512-12.386 53.112-26.986 18.128-18.129 28.111-42.231 28.111-67.868zm-256-240.999c44.651 0 80.979 36.327 80.979 80.978 0 23.056-9.701 43.875-25.217 58.636v-18.455c0-17.642-11.193-32.716-26.848-38.497 3.731-5.546 5.912-12.217 5.912-19.389v-9.864c0-19.203-15.622-34.825-34.825-34.825s-34.825 15.622-34.825 34.825v9.864c0 7.172 2.181 13.844 5.912 19.39-15.656 5.782-26.85 20.855-26.85 38.496v18.454c-15.515-14.762-25.216-35.58-25.216-58.635-.001-44.651 36.327-80.978 80.978-80.978zm0 161.956c-14.857 0-28.781-4.04-40.763-11.052v-29.744c0-14.348 11.673-26.021 26.021-26.021h.715c.009.004.018.008.027.012l-8.216 32.067c-1.036 4.045.269 8.27 3.404 11.025l11.526 10.133c2.077 1.826 4.681 2.739 7.285 2.739s5.209-.913 7.285-2.738l11.525-10.132c3.137-2.756 4.441-6.981 3.405-11.026l-8.214-32.068c.009-.004.018-.008.027-.012h.722c14.344 0 26.014 11.673 26.014 26.021v29.745c-11.983 7.011-25.907 11.051-40.763 11.051zm0-61.008 7.081 27.636-7.081 6.225-7.081-6.225zm19.825-37.674c0 10.931-8.894 19.825-19.825 19.825s-19.825-8.894-19.825-19.825v-9.864c0-10.931 8.894-19.825 19.825-19.825s19.825 8.894 19.825
      19.825zm-115.744 20.912c.125 3.784.466 7.513 1.019 11.175-20.136 13.173-37.584 30.626-50.753 50.764-3.667-.556-7.393-.907-11.167-1.033 15.245-24.884 36.02-45.661 60.901-60.906zm-64.102 176.783 7.08 27.636-7.081 6.225-7.08-6.225zm19.824-37.674c0 10.931-8.894 19.825-19.824 19.825-10.932 0-19.825-8.894-19.825-19.825v-9.864c0-10.931 8.894-19.825 19.825-19.825s19.824 8.894 19.824 19.825zm-34.566 31.865h.715l.027.012-8.216 32.067c-1.036 4.044.269 8.269 3.403 11.026l11.528 10.134c2.077 1.825 4.681 2.737 7.284 2.737 2.604 0 5.209-.913 7.285-2.738l11.526-10.133c3.135-2.756 4.439-6.981 3.403-11.025l-8.216-32.067c.009-.004.018-.008.027-.012h.722c14.344 0 26.014 11.673 26.014 26.021v29.768c-12.303 7.159-26.401 11.028-40.762 11.028-14.857 0-28.781-4.04-40.763-11.052v-29.744c.002-14.35 11.675-26.022 26.023-26.022zm17.943 81.747c3.748-.126 7.474-.479 11.162-1.04 13.177 20.153 30.636 37.614 50.789 50.792-.561 3.689-.914 7.414-1.04 11.163-24.885-15.244-45.661-36.023-60.911-60.915zm116.057 134.039v-29.744c0-14.348 11.673-26.021 26.021-26.021h.715c.009.004.018.008.027.012l-8.217 32.067c-1.036 4.043.269 8.269 3.404 11.026l11.525 10.131c2.077 1.827 4.682 2.741 7.287 2.741 2.604 0 5.208-.913
       7.284-2.739l11.526-10.133c3.136-2.757 4.44-6.982 3.404-11.025l-8.217-32.067c.009-.004.018-.008.027-.012h.722c14.344 0 26.014 11.673 26.014 26.021v29.745c-11.982 7.012-25.905 11.051-40.762 11.051-14.854-.001-28.778-4.041-40.76-11.053zm40.763-49.954 7.081 27.634-7.081 6.225-7.081-6.225zm19.825-37.676c0 10.931-8.894 19.825-19.825 19.825s-19.825-8.894-19.825-19.825v-9.864c0-10.931 8.894-19.825 19.825-19.825s19.825 8.894 19.825 19.825zm35.937 76.341v-18.455c0-17.642-11.193-32.716-26.848-38.497 3.731-5.546 5.912-12.217 5.912-19.389v-9.864c0-19.203-15.622-34.825-34.825-34.825s-34.825 15.622-34.825 34.825v9.864c0 7.172 2.181 13.844 5.912 19.39-15.656 5.782-26.85 20.856-26.85 38.496v18.454c-15.515-14.762-25.216-35.581-25.216-58.636 0-18.534 6.421-36.648 18.081-51.004 11.498-14.157 27.574-24.117 45.267-28.044 4.044-.897 6.595-4.903 5.697-8.947s-4.904-6.595-8.947-5.697c-20.981 4.657-40.038 16.458-53.66 33.231-7.389 9.098-12.983 19.474-16.61 30.511-15.06-10.603-28.313-23.863-38.915-38.923 7.674-2.522 15.021-6.003 21.847-10.375.338-.181.656-.393.96-.622 2.669-1.755 5.265-3.631 7.755-5.657 16.784-13.652 28.577-32.745 33.207-53.761.892-4.045-1.665-8.047-5.711-8.938-4.043-.892-8.046 1.666-8.938 5.71-3.455
        15.685-11.655 30.097-23.314 41.194v-18.518c0-17.642-11.193-32.716-26.849-38.497 3.731-5.546 5.911-12.217 5.911-19.389v-9.864c0-19.203-15.622-34.825-34.824-34.825-19.203 0-34.825 15.622-34.825 34.825v9.864c0
        7.172 2.181 13.844 5.912 19.39-15.656 5.782-26.85 20.855-26.85 38.496v18.454c-15.515-14.761-25.216-35.58-25.216-58.635 0-44.651 36.327-80.978 80.979-80.978 38.599 0 72.011 27.443 79.447 65.254.8 4.064 4.744 6.715 8.807 5.912 4.064-.799 6.711-4.742 5.912-8.806-6.812-34.632-32.006-61.911-64.206-72.534 10.593-15.047 23.836-28.298 38.882-38.895 6.803 20.642 20.431 38.209 38.204 50.018.378.306.786.575 1.22.804 14.943 9.605 32.71 15.182 51.756 15.182 19.047 0 36.815-5.577 51.758-15.184.43-.227.834-.494 1.209-.797 17.776-11.809 31.406-29.376 38.211-50.02 15.034 10.59 28.277 23.832 38.866 38.865-38.286 12.619-66 48.72-66 91.178 0 33.352 17.104 62.78 42.996 79.988.38.308.792.579 1.228.809 6.732 4.327 14.039 7.831 21.776 10.381-10.597 15.046-23.84 28.294-38.886 38.888-10.633-32.256-37.975-57.468-72.69-64.239-4.066-.794-8.004 1.86-8.797 5.926s1.86 8.004 5.926 8.797c37.884 7.389 65.381 40.812 65.381 79.472 0 23.056-9.702 43.875-25.217 58.637zm111.341-171.052-7.08 6.225-7.081-6.225 7.081-27.636zm12.744-65.31c0 10.931-8.894 19.825-19.825 19.825s-19.824-8.894-19.824-19.825v-9.864c0-10.931 8.894-19.825 19.824-19.825 10.932 0 19.825 8.894 19.825 19.825zm-34.567 31.865h.715l.027.012-8.216 32.067c-1.036 4.044.269 
        8.269 3.403 11.026l11.526 10.133c2.076 1.825 4.68 2.738 7.285 2.738 2.604 0 5.208-.913 7.286-2.738l11.526-10.133c3.135-2.756 
        4.439-6.981 3.403-11.025l-8.216-32.067c.009-.004.018-.008.026-.012h.723c14.344 0 26.013 11.673 26.013 26.021v29.809c-12.242 7.157-26.205 10.987-40.762 10.987-14.857 0-28.781-4.04-40.763-11.052v-29.744c.003-14.35 11.676-26.022 26.024-26.022zm-49.372 142.663c-.125-3.775-.475-7.5-1.031-11.168 20.138-13.17 37.588-30.619 50.761-50.757 3.663.553 7.394.894 11.179 1.019-15.247 24.889-36.02 45.66-60.909 60.906zm119.875-98.136v-18.506c0-17.642-11.193-32.716-26.848-38.497 3.731-5.546 5.911-12.217 5.911-19.389v-9.864c0-19.203-15.622-34.825-34.825-34.825-19.202 0-34.824 15.622-34.824 34.825v9.864c0 7.172 2.181 13.844 5.912 19.39-15.657 5.782-26.851 20.855-26.851 38.496v18.453c-15.514-14.762-25.215-35.58-25.215-58.635 0-44.651 36.326-80.978 80.978-80.978s80.979 36.327 80.979 80.978c0 21.63-8.423 41.965-23.718 57.26-.49.49-.999.952-1.499 1.428z"/>
</g>
<g>

</g>
<g>
	<g>
		<path d="M424,392h24v-16h-24v-8c0-3.832-0.712-7.456-1.72-10.968l18.288-18.288l-11.312-11.312l-14.864,14.864
			c-5.696-6.728-13.44-11.664-22.392-13.488V312h-16v16.808c-8.952,1.824-16.696,6.76-22.392,13.488l-14.864-14.864l-11.312,11.312
			l18.288,18.288c-1.008,3.512-1.72,7.136-1.72,10.968v8h-24v16h24v8c0,3.832,0.712,7.456,1.72,10.968l-18.288,18.288l11.312,11.312
			l14.864-14.864c5.696,6.728,13.44,11.664,22.392,13.488V456h16v-16.808c8.952-1.824,16.696-6.76,22.392-13.488l14.864,14.864
			l11.312-11.312l-18.288-18.288c1.008-3.512,1.72-7.136,1.72-10.968V392z M408,400c0,13.232-10.768,24-24,24s-24-10.768-24-24v-32
			c0-13.232,10.768-24,24-24s24,10.768,24,24V400z"/>
	</g>
</g>
<g>
	<g>
		<rect x="376" y="376" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="440" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="104" y="400" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="160" y="336" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="272" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="152" y="240" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="64" y="256" width="16" height="16"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</g>
    
  
`]

