export const briefings = ['369 466',`
  
<g  id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<g>
	<g>
    <path xmlns="http://www.w3.org/2000/svg" d="m479.361 40.89h-78.037c-.384-1.665-1.238-3.007-2.615-4.104l-44.119-35.111c-1.289-1.026-3.035-1.631-4.67-1.631-.021 0-212.609 0-212.609 0-14.777 0-26.8 12.022-26.8 26.8v14.046h-77.824c-18.024 0-32.687 14.663-32.687 32.686v306.834c0 18.023 14.663 32.686 32.687 32.686h166.383v83.909h-44.458c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h202.825c4.143 0 7.5-3.358 
    7.5-7.5s-3.357-7.5-7.5-7.5h-44.458v-83.909h78.976c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-359.268c-9.752
     0-17.687-7.934-17.687-17.686v-25.296h482.048v25.296c0 9.752-7.935 17.686-17.688 17.686l-52.712.004c-4.142 0-7.499 3.358-7.499 7.5s3.358 7.5 7.501 7.5l52.711-.004c18.023 0 32.687-14.663 32.687-32.686v-306.834c0-18.023-14.663-32.686-32.687-32.686zm-234.298 372.206h21.921v37.892c0 6.043-4.917 10.96-10.961 10.96-6.043 0-10.96-4.917-10.96-10.96zm-30.994 
    0h15.994v37.892c0 14.314 11.646 25.96 25.96 25.96s25.961-11.646 25.961-25.96v-37.892h15.994v83.909h-83.909zm143.351-389.999 12.474 9.927 2.677 2.13h-14.813c-.187 0-.338-.152-.338-.339zm29.12 317.017h-261.029v-171.085c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v171.084h-95.511v-266.537c0-9.752 7.935-17.686 17.687-17.686h77.824v78.269c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-107.316c0-6.506 
    5.293-11.8 11.8-11.8h205.109v19.773c0 8.458 6.881 15.339 15.338 15.339h28.782zm110.508 
    0h-95.508c0-40.353 0-231.902 0-284.224h77.821c9.752 0 17.687 7.934 17.687 17.686z"/>
	</g>
</g>
<g>

</g>
<g>
	<g>
		<path d="M424,392h24v-16h-24v-8c0-3.832-0.712-7.456-1.72-10.968l18.288-18.288l-11.312-11.312l-14.864,14.864
			c-5.696-6.728-13.44-11.664-22.392-13.488V312h-16v16.808c-8.952,1.824-16.696,6.76-22.392,13.488l-14.864-14.864l-11.312,11.312
			l18.288,18.288c-1.008,3.512-1.72,7.136-1.72,10.968v8h-24v16h24v8c0,3.832,0.712,7.456,1.72,10.968l-18.288,18.288l11.312,11.312
			l14.864-14.864c5.696,6.728,13.44,11.664,22.392,13.488V456h16v-16.808c8.952-1.824,16.696-6.76,22.392-13.488l14.864,14.864
			l11.312-11.312l-18.288-18.288c1.008-3.512,1.72-7.136,1.72-10.968V392z M408,400c0,13.232-10.768,24-24,24s-24-10.768-24-24v-32
			c0-13.232,10.768-24,24-24s24,10.768,24,24V400z"/>
	</g>
</g>
<g>
	<g>
		<rect x="376" y="376" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="440" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="104" y="400" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="160" y="336" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="208" y="272" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="152" y="240" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="64" y="256" width="16" height="16"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</g>
    
  
`]

